import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  Tag: PropTypes.string,
}
/**
 * HTML.
 */
const HTML = ( { children, tag, ...p } ) => {

  const Tag = tag || 'div';

  return(

    <Tag
    dangerouslySetInnerHTML={ { __html: children } }
    { ...p }
    />

  );

}
HTML.propTypes = propTypes;
export default HTML;