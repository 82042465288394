import classnames from 'classnames';
import React from 'react';

import Footer from 'organisms/Footer';
import Header from 'organisms/Header';
import 'lib/global.scss';


/**
 *  The App.
 */
const App = React.memo( ( { children, ...p } ) => {

  /* ------ = Output. = --------------------------------------------------------------------- */
  return(

    <div id="app">

      <Header path={ p.path } />
        <main>
          { children }
        </main>
      <Footer />

    </div>

  );

} );

export default App;
