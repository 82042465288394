import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './Container.scss';

/**
 * Container.
 */
const propTypes = {
  size: PropTypes.string, // xl, lg, md, sm, xl
}
const Container = React.forwardRef( ( p, ref ) => {

  const className = classnames(
    'Container',
    'container-fluid',
    p.className,
    {
      [`is-${p.size}`]: !! p.size,
    }
  )

  return(

    <div
    ref={ ref }
    className={ className }
    >
      { p.children }
    </div>

  )

} );
Container.propTypes = propTypes;

export default Container;