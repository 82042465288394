/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';

import App from './src/App';
import { initSimpleImg } from 'react-simple-img';

//import { watchVerticalScrollBreakpoints } from 'lib/helpers';


/**
 * Disable prefetching for optimization,
 */
export const disableCorePrefetching = () => true;


/**
 * Wrapping root element with global providers.
 *
 */
// export const wrapRootElement = ( { element } ) => (
//
//   <AppContextProvider>
//     { element }
//   </AppContextProvider>
//
// );


/**
 * Wrapping html with Layout that persists between page transitions.
 */
export const wrapPageElement = ( { element, props } ) => {

  if( typeof window !== 'undefined' ) {

    //require( 'smooth-scroll')( 'a[href*="#"]' );
    window.FUNCTIONS_URL = process.env.ACTIVE_ENV === 'netlifyDev' ? process.env.FUNCTIONS_URL_DEV : process.env.FUNCTIONS_URL_LIVE;


    // Custom image lazy loading.
    initSimpleImg( { rootMargin: '400px' } );

  }

  // Breakpoints.
  //watchVerticalScrollBreakpoints();


  return(

    <App { ...props }>
      { element }
    </App>

  );

};