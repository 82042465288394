import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

/**
 * Icon.
 */
const propTypes = {

}
const Icon = p => {

  const className = classnames( 'Icon', p.className );

  return(

    <i className={ className } />

  )

};
Icon.propTypes = propTypes;

export default Icon;
