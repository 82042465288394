import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Slider } from 'react-burgers';

import Container from 'molecules/Container';
import Icon from 'molecules/Icon';
import Link from 'molecules/Link';
import SRT from 'molecules/SRT';
import { isEmpty } from 'lib/helpers';

import logoImg from '~static/images/epsilon-management-logo.png';
import styles from './Header.scss';


/**
 * Header.
 */
const propTypes = {
  path: PropTypes.string,
}
const Header = React.memo( p => {

  const { sanityHeader, contact } = useStaticQuery( graphql`
    query HeaderQuery {
      sanityHeader {
        primaryMenu {
          dropdownOption
          text
          page {
            ... on SanityPage {
              _type
              slug {
                current
              }
            }
            ...on SanityResource {
              _type
              slug {
                current
              }
            }
            ...on SanityPresentation {
              _type
              slug {
                current
              }
            }
          }
          dropdown {
            text
            page {
              ... on SanityPage {
                slug {
                  current
                }
              }
              ...on SanityResource {
                slug {
                  current
                }
              }
            }
          }
        }
        primaryCTA {
          newTab
          external
          text
          url
          page {
            ... on SanityPage {
              slug {
                current
              }
            }
          }
        }
      }
      contact: sanitySettingsContact {
        nyEmail
        nyPhone
        nyCity
        nyState
        nyStreet
        nyZip
        social {
          name
          url
        }
      }
    }
  ` );

  const headerRef = React.useRef( null );

  const [ isTransitioningMMenu, setIsTransitioningMMenu ] = React.useState( false );
  const [ isShowingMMenu, setIsShowingMMenu ] = React.useState( false );
  const [ isStuck, setIsStuck ] = React.useState( false );


  /* ------ = Functions. = --------------------------------------------------------------------- */

  /**
   * Handle burger click.
   */
  const handleBurgerClick = () => {

    setIsTransitioningMMenu( true );

    // if( isShowingMMenu ) {
    //   setIsShowingMMenu( false )
    // }

    setTimeout( () => {
      setIsShowingMMenu( ! isShowingMMenu );
      setIsTransitioningMMenu( false );
    }, 300 );

  }


  /* ------ = Lifecycle = --------------------------------------------------------------------- */

  // Track scroll positons.
  React.useEffect( () => {

    window.addEventListener( 'scroll', () => {

      const headerEl = headerRef.current;
      if( ! headerEl ) {
        return;
      }
      const rect = headerEl.getBoundingClientRect();
      const doc = document.scrollingElement || document.documentElement

      if( headerEl.classList.contains( 'is-stuck' ) && doc.scrollTop < 50 ) {
        setIsStuck( false );
      }
      else if( ! headerEl.classList.contains( 'is-stuck' ) && doc.scrollTop > 50 ) {
        setIsStuck( true );
      }

    } );

  }, []  );

  // Make sure menu is closed on page transitions.
  React.useEffect( () => {

    setIsTransitioningMMenu( true );

    // if( isShowingMMenu ) {
    //   setIsShowingMMenu( false )
    // }

    setTimeout( () => {
      setIsShowingMMenu( false );
      setIsTransitioningMMenu( false );
    }, 300 );

  }, [ p.path ] )

  /* ------ = Output. = --------------------------------------------------------------------- */
  const className = classnames( 'Hder', {
    'is-stuck': !! isStuck,
    'is-transitioning-mmenu': isTransitioningMMenu,
    'is-showing-mmenu': isShowingMMenu,
  } );


  return(

    <header
    ref={ headerRef }
    className={ className }
    >
      <Container>

        { /* Logo */ }
        <Link to="/">
          <img
          className="Hder__logo"
          src={ logoImg }
          />
        </Link>

        { /* Nav */ }
        <div className={ `Hder__menu d-md-inline-block` }>
          <div className="inner">
            <nav>
              <ul className="cleanlist">

                <li
                className="Hder__menu-item d-md-none"
                >
                  <Link
                  className={ `/` }
                  to={ `/` }
                  >
                    Home
                  </Link>
                </li>

                { !! sanityHeader.primaryMenu && sanityHeader.primaryMenu.map( item => {

                  if( !! item.dropdownOption ) {
                    return null;
                  }


                  const resource = item.page._type === 'resource';
                  const dir = item.page._type === 'resource' ? '/research' : '/';

                  return(

                    <li
                    className={ classnames( 'Hder__menu-item', {
                      ['is-current']: p.path === `/${item.page.slug.current}`,
                    } ) }
                    >
                      <Link
                      className={ `/${item.page.slug.current}` }
                      to={ `${dir}/${item.page.slug.current}` }
                      >
                        { item.text }
                      </Link>
                    </li>

                  )

                } ) }
                { !! sanityHeader.primaryCTA &&

                  <li className="Hder__menu-item">
                    <Link
                    className="cta"
                    to={ `/${sanityHeader.primaryCTA.page.slug.current}` }
                    >
                      { sanityHeader.primaryCTA.text }
                    </Link>
                  </li>

                }
              </ul>
            </nav>


            <div className="Hder__menu-company d-md-none">
              <div className="contact">
                { !! contact.nyStreet &&

                  <div className="office">
                    <h3 className="office__hding">NY Office</h3>
                    { contact.nyStreet }<br/>
                    { `${contact.nyCity}, ${contact.nyState} ${contact.nyZip}` }<br/>

                    <a href={ `tel:${contact.nyPhone}` }>{ contact.nyPhone }</a>

                  </div>



                }
                <ul className="social inlinelist">

                  { !! contact.social.length && contact.social.map( network => (

                    <li key={ network.name }>
                      <a
                      href={ network.url }
                      target="_blank"
                      rel="noopener norefferer"
                      >
                        <Icon className={ `epsilon-${network.name}` } />
                      </a>
                    </li>
                  ) ) }

                </ul>

              </div>

            </div>

            <div className="Hder__menu-brand d-md-none" aria-hidden={ true }>
              <div className="bg"/>
            </div>

          </div>
        </div>

        { /* Burger */ }
        <div
        className={ `Hder__burger d-md-none` }
        onClick={ handleBurgerClick }
        >

          <Slider
          active={ ( ! isShowingMMenu && isTransitioningMMenu ) || isShowingMMenu }
          color={ 'var( --blue-dark )' }
          width={ 30 }
          lineHeight={ 2 }
          padding={ 0 }
          />
          <button className="text">Menu</button>
        </div>

      </Container>
    </header>

  );

} );
Header.propTypes = propTypes;
export default Header;