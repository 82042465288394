// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-author-index-js": () => import("./../../../src/pages/blog/author/index.js" /* webpackChunkName: "component---src-pages-blog-author-index-js" */),
  "component---src-pages-blog-category-index-js": () => import("./../../../src/pages/blog/category/index.js" /* webpackChunkName: "component---src-pages-blog-category-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/Contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-default-index-js": () => import("./../../../src/templates/Default/index.js" /* webpackChunkName: "component---src-templates-default-index-js" */),
  "component---src-templates-letters-index-js": () => import("./../../../src/templates/Letters/index.js" /* webpackChunkName: "component---src-templates-letters-index-js" */),
  "component---src-templates-single-post-index-js": () => import("./../../../src/templates/SinglePost/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */),
  "component---src-templates-single-presentation-index-js": () => import("./../../../src/templates/SinglePresentation/index.js" /* webpackChunkName: "component---src-templates-single-presentation-index-js" */),
  "component---src-templates-single-research-index-js": () => import("./../../../src/templates/SingleResearch/index.js" /* webpackChunkName: "component---src-templates-single-research-index-js" */),
  "component---src-templates-template-home-index-js": () => import("./../../../src/templates/templateHome/index.js" /* webpackChunkName: "component---src-templates-template-home-index-js" */)
}

