import cookies from 'js-cookie';
import each from 'lodash/each';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React from 'react';
import { PrerenderedComponent } from 'react-prerendered-component';

/* ------ = Sanity. = --------------------------------------------------------------------- */


/* ------ = User content. = --------------------------------------------------------------------- */


/* ------ = Breakpoints. = --------------------------------------------------------------------- */

/**
 * Watch vertical scrolling breakpoints.
 */
export const watchVerticalScrollBreakpoints = () => {

  if( typeof window == 'undefined' ) {
    return;
  }

  const breakpoints = {
    top: 1,
    xs: 150,
    sm: 300
  };

  document.onscroll = () => {

    const y = window.scrollY;
    const classes = {}

    for( let bp in breakpoints ) {

      const activatedClass = `passed-breakpoint-${bp}`;
      const bpActivated = document.body.classList.contains( activatedClass );

      // Activate.
      if( y >= breakpoints[bp] && ! bpActivated ) {
        document.body.classList.add( activatedClass );
      }
      // Deactivate.
      else if( y < breakpoints[bp] && bpActivated ) {
        document.body.classList.remove( activatedClass );
      }
    }
  }
}


/* ------ = Async. = --------------------------------------------------------------------- */

/**
 * Loadable components.
 *
 * @param {func} dynamicImport
 */
export const lazy = dynamicImport => loadable( dynamicImport );


/* ------ = Collections = --------------------------------------------------------------------- */
/**
 * Switch component to switch condition and render.
 */
const switchProps = {
  value: PropTypes.string,
  cases: PropTypes.object
}
const Switch = p => p.cases[p.value];
Switch.propTypes = switchProps;

export { Switch };

/* ------ = Styling = --------------------------------------------------------------------- */

/**
 * Lighten color.
 *
 * Replicates the native sass functions.
 *
 * @see https://css-tricks.com/snippets/javascript/lighten-darken-color/
 *
 * @param {string}  color
 * @param {int}     amount   Can be positive for lightening and negative for darkening,
 * @return string.
 */
export const lightenColor = ( color, amount ) => {

    var usePound = false;

    if (color[0] == "#") {
        color = color.slice(1);
        usePound = true;
    }

    var num = parseInt(color,16);

    var r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

/* ------ = String parsing = --------------------------------------------------------------------- */

/**
 * Convert input value to number for usage with getFieldDecorator.
 *
 * @param {Event} e
 * @return int|string.
 */
export const convertValueToInt = e => {

  const value = e.currentTarget.value;

  if( ! value && value !== 0 ) {
    return value;
  }

  const converted = Number( value );

  if( isNaN( converted ) ) {
    return value;
  }

  return converted;

}

/**
* Checks a string for valid JSON
*
* @param {string} string
* @return JSON | bool
*
* @see https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try/3710226#answer-20392392
*/
export const validateJSON = ( string ) => {

  try {

    let validJSON = JSON.parse( string );

    if( validJSON && typeof validJSON === 'object' ) {
      return validJSON;
    }

  }
  catch( error ){}

  return false;

}

/**
* Captilize Strings
*
* @see https://stackoverflow.com/questions/2332811/capitalize-words-in-string
*/
export const capitalize = string => {

  if( ! string.length ) {
    return;
  }
  else {
    return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
  }

}

/**
 * Slugify a string.
 *
 * @param {string} string
 * @return string.
 */
export const slugify = string => {

  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

}

/* ------ = Misc. = --------------------------------------------------------------------- */


/**
 * Copty to clipboard.
 *
 * @param string.
 */
export const copyToClipboard = string => {

  const el = document.createElement( 'textarea' );
  el.value = string;
  document.body.appendChild( el );
  el.select();
  document.execCommand( 'copy' );
  document.body.removeChild (el );

  alert( 'Copied to clipboard!' );

}

/**
* Get query params.
*
* @return Object.
*/
export const getQueryParams = () => {

  if( typeof window == 'undefined' ) {
    return {};
  }

  const hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
  const params = {}

  for( let i = 0; i < hashes.length; i++ ) {

    if( hashes[i].includes( 'http' ) ) {
      continue;
    }

    const hash = hashes[i].split( '=' );
    params[hash[0]] = hash[1];

  }

  return params;

}

/**
 * Check for empty objects.
 *
 * @see https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 * @param {Object} obj
 * @return bool.
 */
export const isEmpty = ( obj ) => {

  if(
    ! obj ||
    typeof obj == 'undefined' ||
    ( Object.keys( obj ).length === 0 && obj.constructor === Object )
  ) {
    return true;
  }
  else {
    return false;
  }

}



/**
 * Breakpoint goodness.
 */
export const breakpoints = {

  sizes: {
    xl:     '1200',
    lg:     '992',
    md:     '768',
    sm:     '576',
    xs:     '0',
  },
  xsUp: function() {
    return window.matchMedia( '(min-width: ' + this.sizes.xs + 'px)' ).matches;
  },
  smUp: function() {
    return window.matchMedia( '(min-width: ' + this.sizes.sm + 'px)' ).matches;
  },
  mdUp: function() {
    return window.matchMedia( '(min-width: ' + this.sizes.md + 'px)' ).matches;
  },
  lgUp: function() {
    return window.matchMedia( '(min-width: ' + this.sizes.lg + 'px)' ).matches;
  },
  xlUp: function() {
    return window.matchMedia( '(min-width: ' + this.sizes.xl + 'px)' ).matches;
  }

}

/**
 * Determine breakpoint.
 */
export const getBreakpoint = () => {

  const width = window.innerWidth;
  let breakpoint = null;

  for( let key in breakpoints.sizes ) {

    if( width >= breakpoints.sizes[key] ) {
      breakpoint = key;
      break;
    }
  }

  return breakpoint;

}

/**
 * Generate unique key.
 *
 * @param {int} length
 */
export const generateUniqueKey = length => {

  const possible = 'abcdefghijklmnopqrstuvwxyz';
  let key = '';

  for( let i = 0; i < length; i++ ) {
    key += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return key;

}