import React from 'react';
import { Link as GatsbyLink } from 'gatsby';


/**
 * Link.
 *
 * Modified Gatsby link for local urls.
 * @see https://www.gatsbyjs.org/docs/gatsby-link/ for props and details.
 */
const Link = ( { children, className, target, to, type, ...gatsbyProps } ) => {

  let modifiedTo = to;


  if(
    target === '_blank' ||
    to.includes( 'http' ) ||
    to.includes( 'tel:' ) ||
    to.includes( 'mailto:' )
  ) {

    return(
      <a
      className={ className }
      href={ to }
      target={ target }
      rel="noopener noreferrer"
      { ...gatsbyProps }
      >
        { children }
      </a>
    );

  }

  if( type === 'post' && ! to.includes( 'blog/' ) ) {
    modifiedTo = `blog${to}`;
  }
  if( modifiedTo.charAt( 1 ) === '/' ) {
    modifiedTo = modifiedTo.substring( 1 );
  }


  return(

    <GatsbyLink
    className={ className }
    to={ modifiedTo }
    { ...gatsbyProps }
    >
      { children }
    </GatsbyLink>

  );

}
export default Link;