import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { SimpleImg } from 'react-simple-img';

import './Image.scss';

/**
 * Image.
 *
 * @see https://github.com/bluebill1049/react-simple-img#-simpleimg
 */
const propTypes = {
  src: PropTypes.string,
  data: PropTypes.object,
  originalSize: PropTypes.boolean,
  // See additional simpleimg props.
}
const Image = ( { className: customClassName, ...p } ) => {


  let imageSrc = !! p.src ? p.src : !! p.data && p.data.url ? p.data.url : null;
  let width = null;
  let height = null;

  if( p.originalSize ) {
    let dimensions = ! imageSrc ? '' : imageSrc.substring( imageSrc.lastIndexOf( '-' ) + 1, imageSrc.lastIndexOf( '.png' ) );
    dimensions = dimensions.split( 'x' );
    width = parseInt( dimensions[0] );
    height = parseInt( dimensions[1] );
    imageSrc = imageSrc + `?h=${height*2}`;
  }

  

  let placeholder = !! p.data ? p.data.metadata.lqip : null;
  if( !! imageSrc ) {
    imageSrc = imageSrc.replace( 'cdn.sanity.io', 'assets.epsilonmgmt.com' );
  }
  if( !! placeholder ) {
    placeholder = placeholder.replace( 'cdn.sanity.io', 'assets.epsilonmgmt.com' );
  }

  const [ isLoaded, setIsLoaded ] = React.useState( false );
  // React.useEffect( () => {
  //   setIsLoaded( true )
  // }, [] );

  /* ------ = Output = --------------------------------------------------------------------- */
  const className = classnames( 'Img', customClassName, {
    'is-original-size': !! p.originalSize
  } );
  
  return(

    <SimpleImg
    className={ className }
    style={
      !! p.originalSize && {
        maxWidth: width
      }
    }
    src={ imageSrc }
    placeholder={ isLoaded ? imageSrc : placeholder }
    { ...p }
    { ... !! p.originalSize && {
      imgStyle: {
        maxWidth: width
      }
    } }
    />

  );

};

export default Image;
