import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FaTwitter, FaLinkeinIn } from 'react-icons/fa';

import Button from 'molecules/Button';
import Container from 'molecules/Container';
import HTML from 'molecules/HTML';
import Icon from 'molecules/Icon';
import Link from 'molecules/Link';
import WithBGImage from 'molecules/WithBGImage';
//import { isEmpty } from 'lib/helpers';

//import logoImg from '~static/images/epsilon-management-logo.png';
import styles from './Footer.scss';


/**
* Header.
*/
const Header = React.memo( p => {

  const { footer: { content }, contact } = useStaticQuery( graphql`
    query FooterQuery {
      footer: sanityFooter {
        content {
          contactBG {
            asset {
              url
              metadata {
                lqip
              }
            }
          }
          headline
          preheadline
          ctaLink {
            page {
              ... on SanityPage {
                id
                slug {
                  current
                }
              }
            }
            text
            url
            external
          }
        }
      }
      contact: sanitySettingsContact {
        nyEmail
        nyPhone
        nyCity
        nyState
        nyStreet
        nyZip
        social {
          name
          url
        }
      }
    }
    ` );

    const date = new Date();
    const year = date.getFullYear();

    /* ------ = Output. = --------------------------------------------------------------------- */

    return(

      <footer
        className="Footer"
        >

        {/* <WithBGImage
        className="Footer__contact"
        image={ {
          data: content.contactBG.asset
        } }
        overlay={ true }
        >
          <Container>

            <h2 className="Footer__contact-hding h-serif-md">
              <span className="pre">{ content.preheadline}</span>
              <HTML className="main">
                { content.headline }
              </HTML>
            </h2>

            { !! content.ctaLink &&

              <Button
              size="lg"
              color="transparent"
              link={ {
                to: `/${content.ctaLink.page.slug.current}`
              } }
              >
                { content.ctaLink.text }
              </Button>

            }


          </Container>
        </WithBGImage> */}

        <Container>

          <div className="Footer__company">

            <h2 className="Footer__company-hding">Epsilon Asset Management</h2>
            <div className="Footer__company-address">
              { contact.nyStreet }<br/>
            {  `${contact.nyCity}, ${contact.nyState} ${contact.nyZip}` }

            <ul className="Footer__social inlinelist">

              { !! contact.social.length && contact.social.map( network => (

                <li key={ network.name }>
                  <a
                    href={ network.url }
                    target="_blank"
                    rel="noopener norefferer"
                    >
                    <Icon className={ `epsilon-${network.name}` } />
                  </a>
                </li>
              ) ) }

            </ul>

          </div>

          <div className="Footer__bottom">
            © { year } Epsilon.
            <Link to={ '/terms' }>
              Terms of Use
            </Link>
          </div>

        </div>



      </Container>
    </footer>

  );

} );
export default Header;